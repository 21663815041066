import * as React from "react"
import styled from 'styled-components/macro'
import Title from "../../common/Title";
import Subtitle from "../../common/Subtitle";
import Link from "../../common/Link"
import TopAngle from "../../common/TopAngle"
import BottomAngle from "../../common/BottomAngle"
import ETHLogo from '../../images/assets/ETH-logo.svg'
import BorrowGraphicBottom from '../../images/powerful-borrowing-graphic-animated-bottom.svg'
import BorrowGraphicTop from '../../images/powerful-borrowing-graphic-animated-top.svg'
import BorrowGraphic from '../../images/powerful-borrowing-graphic-static.svg'
import LINKLogo from '../../images/assets/chainlink-logo.svg'
import BTCLogo from '../../images/assets/bitcoin-logo.svg'
import DAILogo from '../../images/assets/DAI-logo.svg'
import USDCLogo from '../../images/assets/USDC-logo.svg'
import GMXLogo from '../../images/assets/gmx-logo-circle.png'
import DPXLogo from '../../images/assets/dpx-logo.png'
import {keyframes} from "styled-components";

const BorrowSectionWrapper = styled.div`
  width: 100%;
  color: #f9f9f9;
  padding-bottom: 100px;
  padding-top: 100px;
  position: relative;
  
  > div:nth-of-type(3) {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 50px;
  }
  
  @media screen and (max-width: 880px) {
    > div:nth-of-type(3) {
      display: flex;
      flex-direction: column-reverse;
      max-width: 480px;
      padding: 0;
    }
  }
`

const Content = styled.div`
  width: calc(70% - 75px);
  padding: 25px;
  padding-left: 0;
  margin: 0 auto;
  display: inline-block;
  vertical-align: top;
  
  @media screen and (max-width: 880px) {
    width: calc(100% - 50px);
    padding: 25px 20px;
  }
`

const GraphicWrapper = styled.div`
  width: 30%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 180px;
  margin-right: -20%;
  width: 25%;
  margin-left: 5%;
  
  @media screen and (max-width: 880px) {
    width: 50%;
    margin: 0 auto 30px;
  }
  
  @media screen and (max-width: 550px) {
    width: 200px;
    margin: 0 auto 0;
  }
`

const BottomImage = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`

const TokenImage = styled.div`
  width: 100%;
  
  img {
    width: 100%;
  }
`

const BlinkAnimation = keyframes`
  0% {
    visibility: visible;
  }

  15% {
    visibility: hidden;
  }
  
  100% {
    visible: hidden;
  }
`

const Image = styled.div`
  visibility: hidden;
  width: 30%;
  position: absolute;
  left: 30%;
  bottom: 60%;
  animation-name: ${BlinkAnimation};
  animation-duration: 3.5s; 
  animation-timing-function: ease-in-out; 
  animation-delay: 0s;
  animation-iteration-count: infinite;
  
  :nth-of-type(2) {
    animation-delay: 0.5s;
  }
  
  :nth-of-type(3) {
    animation-delay: 1s;
  }
  
  :nth-of-type(4) {
    animation-delay: 1.5s;
  }
  
  :nth-of-type(5) {
    animation-delay: 2s;
  }
  
  :nth-of-type(6) {
    animation-delay: 2.5s;
  }
  
  :nth-of-type(7) {
    animation-delay: 3s;
  }
`

const TopImage = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`

// markup
const BorrowSection = () => {

  return (
    <BorrowSectionWrapper>
      <TopAngle />
      <BottomAngle />
      <div>
        <Content>
          <Title>
            Flexible, powerful, secure borrowing
          </Title>
          <Subtitle>
            Dolomite is built with flexibility and power in mind. Open multiple borrow positions from the same wallet, each collateralized by up to 30+ different assets. Rest easy with the knowledge that your loans are secured in isolated positions, where each one remains protected if a liquidation affects another.
          </Subtitle>
          <Link dest={'https://app.dolomite.io/borrow'} text={'Start borrowing'} />
        </Content>
        <GraphicWrapper>
          {/*<BottomImage>
            <img src={BorrowGraphicBottom} />
          </BottomImage>
          <TokenImage>
            <Image>
              <img src={ETHLogo} />
            </Image>
            <Image>
              <img src={LINKLogo} />
            </Image>
            <Image>
              <img src={USDCLogo} />
            </Image>
            <Image>
              <img src={BTCLogo} />
            </Image>
            <Image>
              <img src={DPXLogo} />
            </Image>
            <Image>
              <img src={DAILogo} />
            </Image>
            <Image>
              <img src={GMXLogo} />
            </Image>
          </TokenImage>
          <TopImage>
            <img src={BorrowGraphicTop} />
          </TopImage>*/}
          <img src={BorrowGraphic} />
        </GraphicWrapper>
      </div>
    </BorrowSectionWrapper>
  )
}

export default BorrowSection
